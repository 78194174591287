import React from 'react'
import styled from 'styled-components'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const ConnectButton = styled(Button)`
 
background-image: linear-gradient(90deg, rgb(0, 121, 181) 0%,rgb(33, 155, 216) 51%,rgb(0, 121, 181)) !important;


`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)





  return (
    <ConnectButton onClick={onPresentConnectModal} {...props}>
      {t('Connect Wallet')}
    </ConnectButton>
  )
}

export default ConnectWalletButton
